'use client';

import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import PpModal from '@haaretz/s-pp-modal';
import RichText from '@haaretz/s-rich-text';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import type { ModalProps } from '@haaretz/s-modal';

// `c` is short for `classNames`
const c = s9.create({
  contentWrapper: {
    display: 'grid',
    rowGap: space(4),
    marginTop: space(3),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...merge({
      ...mq({
        from: 'xl',
        value: {
          rowGap: space(6),
        },
      }),
    }),
  },
  title: {
    fontWeight: 700,
    marginBottom: space(-1),
    ...merge(
      fork({
        default: {
          ...mq({
            until: 'l',
            value: {
              ...typesetter(2),
            },
          }),
          ...mq({
            from: 'l',
            until: 'xxl',
            value: {
              ...typesetter(3),
            },
          }),
          ...mq({
            from: 'xxl',
            value: {
              ...typesetter(2),
            },
          }),
        },
        hdc: {
          ...mq({
            until: 'l',
            value: {
              ...typesetter(1),
            },
          }),
          ...mq({
            from: 'l',
            until: 'xxl',
            value: {
              ...typesetter(2),
            },
          }),
          ...mq({
            from: 'xxl',
            value: {
              ...typesetter(1),
            },
          }),
        },
      })
    ),
  },
  termsTextContainer: {
    display: 'grid',
    rowGap: fork<ReturnType<typeof space> | 0>({
      default: space(2),
      hdc: 0,
    }),
  },
  termsText: {
    fontWeight: 300,
    ...typesetter(0),
    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),
  },
  confirmButton: {
    marginTop: space(4),
    marginBottom: space(5),
    ...mq({
      from: 's',
      value: {
        marginTop: space(2),
        marginBottom: space(2),
      },
    }),
  },
});

export type PpTermsProps = {
  /** The title of the modal. */
  title: string;
  /** The textual content of the modal. */
  content: HtmlNodeFragment[];
  /** Controls the opening and closing of the modal */
  isOpen?: boolean;
  /** A callback to update ascendant state when a modal by and action inside it */
  onToggle: ModalProps['onToggle'];
  labelledBy: NonNullable<ModalProps['labelledBy']>;
} & (
  | {
      onButtonClick: (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        biDataExtend?: BiDataOverrides | undefined
      ) => void;
      loading: boolean;
    }
  | {
      onButtonClick?: never;
      loading?: never;
    }
);

export default function PpTerms({
  labelledBy,
  title,
  content,
  isOpen = false,
  onToggle,
  onButtonClick,
  loading,
}: PpTermsProps) {
  const closeModal = () => {
    if (onToggle) onToggle(false);
  };

  const buttonText = fork({ default: 'הבנתי, אפשר להמשיך', hdc: 'Accept' });

  return (
    <PpModal
      onToggle={onToggle}
      isOpen={isOpen}
      labelledBy={labelledBy}
      closeBtnOnClick={closeModal}
      data-testid="pp-terms"
    >
      <div className={s9(c.contentWrapper)}>
        <h2 className={s9(c.title)} id={labelledBy}>
          {title}
        </h2>
        <div className={s9(c.termsTextContainer)}>
          {content.map(
            (paragraph, i) =>
              paragraph.content && (
                <RichText
                  as="p"
                  key={paragraph.content || i}
                  content={paragraph.content}
                  styleExtend={[c.termsText]}
                />
              )
          )}
        </div>
        <Button
          stretch
          variant="brand"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (typeof onButtonClick === 'function')
              onButtonClick(event, { campaign_details: buttonText });
            else closeModal();
          }}
          size="regular"
          styleExtend={[c.confirmButton]}
          data-testid="pp-terms-accept-btn"
          {...(loading ? { state: 'busy', busyNotice: '' } : { state: 'auto' })}
        >
          {buttonText}
        </Button>
      </div>
    </PpModal>
  );
}
