'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import SuccessIcon from '@haaretz/s-success-icon';
import TextFieldWithButton from '@haaretz/s-text-field-with-button';
import useBi from '@haaretz/s-use-bi';
import { usePPUpdateCouponStore } from '@haaretz/s-use-pp-data-store';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import s9 from 'style9';

import type { ButtonState } from '@haaretz/s-button';
import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { PurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    maxWidth: `min(${fork({ default: space(100), hdc: space(87.5) })}, 100vw - ${space(6)})`,

    ...merge(
      mq({ from: 's', until: 'l', value: fork({ default: {}, hdc: { maxWidth: space(92) } }) }),
      mq({ from: 'xl', value: fork({ default: {}, hdc: { maxWidth: space(103.5) } }) })
    ),
  },
  successDiv: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  successText: {
    alignItems: 'center',
    color: color('quaternary800'),
    display: 'flex',
    justifyContent: 'center',
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...typesetter(0),

    ...mq({ from: 'xxl', value: fork({ default: {}, hdc: { ...typesetter(-1) } }) }),
  },
  button: {
    ...typesetter(-1),
  },
  successIconWrapper: {
    marginInlineEnd: space(1),
  },
});

type ComponentToShow = 'textfield' | 'success';

export type PpCouponProps = {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  onUpdateCoupon?: (coupon: string) => void;
  defaultComponentToShow?: ComponentToShow;
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
};
type BtnStateType = ButtonState['state'];

const ppCouponTexts = {
  btnContent: fork({
    default: 'שליחה',
    hdc: 'Redeem',
  }),
  label: fork({
    default: 'נא להזין קופון',
    hdc: 'Promo Code',
  }),
  description: fork({
    default: '',
    hdc: 'Have a promo code? Enter it here',
  }),
  success: fork({
    default: 'הקופון הוזן בהצלחה!',
    hdc: 'Promo code applied!',
  }),
  remove: fork({
    default: 'להסרת הקופון',
    hdc: 'Remove promo code',
  }),
  busyNotice: fork({
    default: 'מעדכן קופון',
    hdc: 'Updating promo code',
  }),
  error: fork({
    default: 'אירעה שגיאה, אנא נסה שנית',
    hdc: 'Something happend. Please try again',
  }),
};

export default function PpCoupon({
  onUpdateCoupon,
  inlineStyle,
  styleExtend = [],
  defaultComponentToShow = 'textfield',
  biData,
}: PpCouponProps) {
  const [componentToShow, setComponentToShow] =
    React.useState<ComponentToShow>(defaultComponentToShow);

  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [btnState, setBtnState] = React.useState<BtnStateType>('auto');
  const [btnBusyNotice, setBtnBusyNotice] = React.useState<string>('');
  const [serverError, setServerError] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const client = useQueryClient();

  const variables = usePpDataVariables();

  const variablesToObserve = React.useMemo(
    () => ({ ...variables, previousSteps: '' }),
    [variables]
  );

  const updateCoupon = usePPUpdateCouponStore();
  const biAction = useBi();

  React.useEffect(() => {
    if (componentToShow === 'textfield') {
      (async () => {
        let ppData = client.getQueryData<PurchasePagePersonalQuery>(
          usePurchasePagePersonalQuery.getKey(variables)
        );

        if (!ppData?.PurchasePage) {
          try {
            ppData = await client.fetchQuery<PurchasePagePersonalQuery>({
              queryKey: usePurchasePagePersonalQuery.getKey(variables),
              queryFn: usePurchasePagePersonalQuery.fetcher(variables),
            });
          } catch (error) {
            setBtnState('auto');
            setBtnBusyNotice('');
            setErrorMsg(ppCouponTexts.error);
            setServerError(true);
          }
        }

        if (ppData?.PurchasePage) {
          if (ppData.PurchasePage?.pageData.couponErrorMessage) {
            setErrorMsg(ppData.PurchasePage?.pageData.couponErrorMessage);
            setBtnState('auto');
            setBtnBusyNotice('');
            updateCoupon(null);
          } else if (
            ppData.PurchasePage?.pageData.personalCouponId ||
            ppData.PurchasePage?.pageData.couponId
          ) {
            setBtnState('auto');
            setBtnBusyNotice('');
            onUpdateCoupon?.(
              ppData.PurchasePage.pageData.personalCouponId ||
                ppData.PurchasePage.pageData.couponId ||
                ''
            );
            setComponentToShow('success');
          } else {
            setBtnState('auto');
            setBtnBusyNotice('');
            updateCoupon(null);
          }
        }

        if (!ppData?.PurchasePage) {
          setBtnState('auto');
          setBtnBusyNotice('');
          setErrorMsg(ppCouponTexts.error);
          setServerError(true);
        }
      })();
    }
  }, [client, componentToShow, onUpdateCoupon, updateCoupon, variables, variablesToObserve]);

  const onCancellation = React.useCallback(
    (_: React.FormEvent<HTMLButtonElement>) => {
      updateCoupon(null);
      if (errorMsg) {
        setErrorMsg('');
      }

      if (componentToShow === 'success') {
        setComponentToShow('textfield');
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [componentToShow, errorMsg, updateCoupon]
  );

  const checkCoupon = React.useCallback(
    (event: React.MouseEvent<HTMLFormElement>) => {
      event.preventDefault();

      biData && biAction({ ...biData, action_id: 24 });
      setServerError(false);
      const couponValue = inputRef.current?.value.trim() || '';
      updateCoupon(couponValue);

      setBtnState('busy');
      setBtnBusyNotice(ppCouponTexts.busyNotice);
    },
    [biAction, biData, updateCoupon]
  );

  const onInput = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.currentTarget.value) {
      setErrorMsg('');
      setServerError(false);
    }
  }, []);

  return (
    <form
      onSubmit={checkCoupon}
      className={s9(c.base, ...styleExtend)}
      style={inlineStyle}
      data-testid="pp-coupon"
    >
      {componentToShow === 'textfield' ? (
        <TextFieldWithButton
          btnContent={ppCouponTexts.btnContent}
          label={ppCouponTexts.label}
          description={errorMsg || ppCouponTexts.description}
          isInvalid={!!errorMsg && !serverError}
          isInvalidDescription={serverError}
          onChange={onInput}
          btnProps={{
            variant: 'neutral',
            type: 'submit',
            ...(btnState === 'busy'
              ? { state: btnState, busyNotice: btnBusyNotice }
              : { state: btnState }),
            styleExtend: [c.button],
          }}
          type="text"
          ref={inputRef}
        />
      ) : (
        <div className={s9(c.successDiv)} data-testid="pp-coupon-success">
          <div className={s9(c.successText)}>
            <SuccessIcon styleExtend={[c.successIconWrapper]} />
            {ppCouponTexts.success}
          </div>
          <Button
            variant="brand"
            size="small"
            priority="tertiary"
            type="button"
            onClick={onCancellation}
          >
            {ppCouponTexts.remove}
          </Button>
        </div>
      )}
    </form>
  );
}
