import 'server-only';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import React from 'react';
import s9 from 'style9';

import type { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import type { HtmlNodeTags } from '@haaretz/s-fragments/Types';
import type { InlineStyles, PolymorphicPropsWithoutRef, StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  blockElement: {
    wordBreak: 'break-word',
  },
  p: {
    // HACK: Don't remove it because we need that for override styles on each breakpoint
    ...merge(
      mq({ until: 's', value: { ...typesetter(1) } }),
      mq({ from: 's', until: 'm', value: { ...typesetter(1) } }),
      mq({ from: 'm', until: 'l', value: { ...typesetter(1) } }),
      mq({ from: 'l', until: 'xl', value: { ...typesetter(1) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(1) } }),
      mq({ from: 'xxl', value: { ...typesetter(1) } })
    ),
  },
  crosshead: {
    // Remove spacing between crosshead and the paragraph beneath it
    marginBottom: 'calc(-1 * var(--rowSpacing))',
    ...typesetter(4),
    // HACK: Don't remove it because we need that for override styles on each breakpoint
    ...merge(
      mq({ until: 's', value: { ...typesetter(4) } }),
      mq({ from: 's', until: 'm', value: { ...typesetter(3) } }),
      mq({ from: 'm', until: 'l', value: { ...typesetter(3) } }),
      mq({ from: 'l', until: 'xl', value: { ...typesetter(3) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(3) } }),
      mq({ from: 'xxl', value: { ...typesetter(4) } })
    ),
  },
  em: { fontStyle: 'italic' },
  strong: { fontWeight: 700 },
  mark: {
    backgroundColor: color('markBg'),
  },
  link: {
    outlineWidth: 0,
    textDecoration: 'underline',
    textDecorationColor: color('primary500'),
    textDecorationThickness: '0.1em',
    textUnderlineOffset: '0.15em',
    transitionProperty: 'all',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out',
    ':hover': {
      color: color('primary1000'),
      textDecorationThickness: '0',
      textDecorationColor: 'transparent',
    },
    ':focus': {
      color: color('primary1000'),
      textDecorationThickness: '0',
      textDecorationColor: 'transparent',
    },
  },
  u: {
    textDecoration: 'underline',
    textDecorationSkip: 'ink',
  },
  list: {
    paddingInlineStart: space(7),
    display: 'grid',
    rowGap: space(1),
    ...merge(
      mq({ until: 's', value: { ...typesetter(1) } }),
      mq({ from: 's', until: 'm', value: { ...typesetter(1) } }),
      mq({ from: 'm', until: 'l', value: { ...typesetter(1) } }),
      mq({ from: 'l', until: 'xl', value: { ...typesetter(1) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(1) } }),
      mq({ from: 'xxl', value: { ...typesetter(0) } })
    ),
  },
  ul: {
    listStyleType: 'disc',
  },
  ol: {
    listStyleType: 'decimal',
  },
  li: {
    paddingInlineStart: space(3),
  },
});

export type AllowedElements = HtmlNodeFragment['as'];
export const DEFAULT_ELEMENT: AllowedElements = 'p';
type DefaultElement = typeof DEFAULT_ELEMENT;

const allowedElements: AllowedElements[] = ['p', 'ul', 'ol', 'li', 'h1', 'h3', 'span'];

export interface RichTextOwnProps<As extends React.ElementType = DefaultElement> {
  /**
   * The HTML element (`${AllowedElements}`) the `<RichText />` will be rendered as.
   *
   * @defaultValue 'p'
   */
  as?: As;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyRichText(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <RichText
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <RichText styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * Same as styleExtend for 'strong' class.
   */
  strongStyleExtend?: StyleExtend;
  /**
   * Same as styleExtend for 'p' class.
   */
  paragraphStyleExtend?: StyleExtend;
  /**
   * The html string to set the elment's innerHtml.
   * Has to be replaced with style9 classes first.
   */
  content: HtmlNodeFragment['content'];
}

export type RichTextProps<As extends React.ElementType = DefaultElement> =
  PolymorphicPropsWithoutRef<RichTextOwnProps<As>, As, AllowedElements>;

function replaceAll(str: string, mapObj: { [key: string]: string }) {
  if (typeof str !== 'string') {
    return '';
  }
  const replaceStr = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(replaceStr, matched => mapObj[matched.toLowerCase()] || '#placeholder#');
}

const blockElemesClassesMap = {
  p: c.p,
  h3: c.crosshead,
  ul: c.ul,
  ol: c.ol,
  list: c.list,
} as const;

export default function RichText<As extends React.ElementType = AllowedElements>({
  as,
  inlineStyle,
  styleExtend = [],
  content,
  strongStyleExtend = [],
  paragraphStyleExtend = [],
  ...attrs
}: RichTextProps<As>) {
  const Element: React.ElementType = as || DEFAULT_ELEMENT;
  if (
    (typeof as === 'string' && !allowedElements.includes(as as HtmlNodeTags)) ||
    typeof as === 'function'
  ) {
    console.error(
      `<RichTextProps /> may only render a "p", "h3", "h1", "ul", "ol" or  a "li" element.\nYou passed "${as}" to the "as" prop`
    );

    return null;
  }

  let blockElemClasses: StyleExtend = [];

  switch (as) {
    case 'p':
      blockElemClasses = [blockElemesClassesMap.p, ...paragraphStyleExtend];
      break;
    case 'h3':
      blockElemClasses = [blockElemesClassesMap.h3];
      break;
    case 'ul':
      blockElemClasses = [blockElemesClassesMap.ul, blockElemesClassesMap.list];
      break;
    case 'ol':
      blockElemClasses = [blockElemesClassesMap.ol, blockElemesClassesMap.list];
      break;
    default:
      blockElemClasses = [];
  }

  const inlineElemsClasses = {
    '#em#': s9(c.em),
    '#strong#': s9(c.strong, ...strongStyleExtend),
    '#a#': s9(c.link),
    '#mark#': s9(c.mark),
    '#u#': s9(c.u),
    '#li#': s9(c.li),
  } as const;

  const html = replaceAll(content, inlineElemsClasses);

  return (
    <Element
      data-testid="rich-text"
      // This is actually React.ComponentPropsWithoutRef<As>, but we cast it
      // as `any` because there is an annoying type discrepency where the deprecated
      // `align` attribute is marked as required _only on some
      // elements but not on others_, causing a type error on polymorphic elements
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(attrs as any)}
      className={s9(c.blockElement, ...blockElemClasses, ...styleExtend)}
      style={inlineStyle}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
